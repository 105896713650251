import {
    FrenchCanada,
    EnglishCanada,
    EnglishNewZealand,
    EnglishAustralia,
    EnglishHongKong,
    EnglishSingapore,
    EnglishJapan,
} from './Locale';

const getFirstDayOfTheWeekOfLocale = (locale) => {
    const zeroBasedLocales = [
        FrenchCanada,
        EnglishCanada,
        EnglishNewZealand,
        EnglishAustralia,
        EnglishHongKong,
        EnglishSingapore,
        EnglishJapan,
    ];
    if (zeroBasedLocales.includes(locale)) {
        return 0;
    }
    return 1;
};

module.exports = {
    getFirstDayOfTheWeekOfLocale,
};
